<template>
  <div style="overflow: auto;">
    <van-nav-bar
      v-if="!$isMobile && ['single_chat_tools', 'group_chat_tools'].includes(contextEntry)"
      fixed
      placeholder
      :title="$route.meta.title"
      left-text="返回"
      left-arrow
      z-index="1000"
      @click-left="$router.back()"
    />
    <div class="item-wrap">
      <div class="top">
        <p class="title">
          {{ item.title }}
        </p>
        <span class="time">
          {{ $moment(item.updateTime).format("YYYY-MM-DD") }}
        </span>
      </div>

      <p class="desc">
        {{ item.content }}
      </p>
      <Tags
        style="margin-top: 5px;"
        :item="item"
        :typeData="typeData"
        :tagConfig="tagConfig"
      ></Tags>
      <div v-if="['single_chat_tools', 'group_chat_tools'].includes(contextEntry)" class="send-action">
        <van-button
          type="primary"
          size="mini"
          round
          @click="$router.push('/talk-skill-edit-content?id=' + item.id)"
        >
          {{ $t("编辑发送") }}
        </van-button>
        <van-button
          style="margin-left: 16px;"
          size="mini"
          round
          type="primary"
          @click="sendMessage(item.content)"
        >
          {{ $t("发送") }}
        </van-button>
      </div>
    </div>
    <van-tabbar active-color="#0D171A" inactive-color="#0D171A">
      <van-tabbar-item @click="onStart">
        <span v-if="isStartO" style="color:#B9921A">
          {{ $t("已收藏") }}
        </span>
        <span v-else>
          {{ $t("收藏") }}
        </span>
        <template #icon>
          <van-icon v-if="isStartO" name="star" color="#B9921A" />
          <van-icon v-else name="star-o" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item @click="copy">
        {{ $t("复制") }}
        <template #icon>
          <svg
            t="1710922533223"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3235"
            width="22"
            height="22"
          >
            <path
              d="M753.784471 870.520471c0 34.334118-26.985412 62.162824-60.235295 62.223058h-542.117647c-33.310118 0-60.235294-27.888941-60.235294-62.223058v-559.585883c0-34.334118 26.925176-62.162824 60.235294-62.162823h542.117647c33.249882 0 60.235294 27.828706 60.235295 62.162823v559.585883z m-60.235295-683.91153h-542.117647c-66.56 0-120.470588 55.657412-120.470588 124.325647v559.585883c0 68.668235 53.910588 124.385882 120.470588 124.385882h542.117647c66.499765 0 120.470588-55.657412 120.470589-124.385882v-559.585883c0-68.668235-53.970824-124.325647-120.470589-124.325647"
              fill="#0D171A"
              p-id="3236"
            ></path>
            <path
              d="M211.365647 808.056471h240.941177v-62.223059h-240.941177v62.223059z m0-124.385883h421.647059v-62.162823h-421.647059v62.162823z m0-124.325647h421.647059V497.121882h-421.647059v62.223059z m0-124.385882h421.647059V372.856471h-421.647059V434.898824z"
              fill="#0D171A"
              p-id="3237"
            ></path>
            <path
              d="M875.098353 0.180706h-542.117647c-66.56 0-120.470588 55.657412-120.470588 124.325647h60.235294c0-34.334118 26.985412-62.162824 60.235294-62.162824h542.117647c33.249882 0 60.235294 27.828706 60.235294 62.162824v559.585882c0 34.334118-26.985412 62.162824-60.235294 62.223059v62.162824c66.56 0 120.470588-55.657412 120.470588-124.385883v-559.585882c0-68.668235-53.910588-124.325647-120.470588-124.325647"
              fill="#0D171A"
              p-id="3238"
            ></path>
          </svg>
        </template>
      </van-tabbar-item>
      <van-tabbar-item v-if="item.speechCraftType == 1" @click="createById">
        {{ $t("个人") }}
        <template #icon>
          <svg
            id="mx_n_1713430825099"
            t="1713430825098"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="7845"
            width="22"
            height="22"
          >
            <path
              d="M1024 972.8c0 28.275-22.925 51.2-51.2 51.2H51.2C22.922 1024 0 1001.075 0 972.8V51.2C0 22.922 22.922 0 51.2 0h921.6c28.275 0 51.2 22.922 51.2 51.2v921.6z m-51.2-870.4c0-28.277-22.925-51.2-51.2-51.2H102.4c-28.275 0-51.2 22.923-51.2 51.2v819.2c0 28.275 22.925 51.2 51.2 51.2h819.2c28.275 0 51.2-22.925 51.2-51.2V102.4z"
              p-id="7846"
              fill="#0D171A"
            ></path>
            <path
              d="M768 512c0 14.14-11.46 25.6-25.6 25.6H537.6v209.176c0 11.72-11.46 21.225-25.6 21.225s-25.6-9.505-25.6-21.225V537.6H281.6c-14.14 0-25.6-11.46-25.6-25.6s11.46-25.6 25.6-25.6h204.8V277.225C486.4 265.5 497.86 256 512 256s25.6 9.5 25.6 21.225V486.4h204.8c14.14 0 25.6 11.46 25.6 25.6z"
              p-id="7847"
              fill="#0D171A"
            ></path>
          </svg>
        </template>
      </van-tabbar-item>
      <van-tabbar-item
        v-if="item.speechCraftType == 2"
        @click="$router.push(`/talk-skill-edit?id=${item.id}`)"
      >
        {{ $t("编辑") }}
        <template #icon>
          <svg
            t="1710922649738"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4385"
            width="22"
            height="22"
          >
            <path
              d="M862.709333 116.042667a32 32 0 1 1 45.248 45.248L455.445333 613.813333a32 32 0 1 1-45.258666-45.258666L862.709333 116.053333zM853.333333 448a32 32 0 0 1 64 0v352c0 64.8-52.533333 117.333333-117.333333 117.333333H224c-64.8 0-117.333333-52.533333-117.333333-117.333333V224c0-64.8 52.533333-117.333333 117.333333-117.333333h341.333333a32 32 0 0 1 0 64H224a53.333333 53.333333 0 0 0-53.333333 53.333333v576a53.333333 53.333333 0 0 0 53.333333 53.333333h576a53.333333 53.333333 0 0 0 53.333333-53.333333V448z"
              fill="#0D171A"
              p-id="4386"
            ></path>
          </svg>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import {
  speechCraftDetail,
  speechCount,
  copyToPersonal,
} from '@/services/talk-skill.js'
import mixins from './mixins.js'
import Tags from './tags'
import vendorServices from '@/services/vendorServices'
import { getWxEntry } from '@/utils'
import wxSDK from '@/scripts/wxsdk'
import loading from '@/utils/loading'
export default {
  components: {
    Tags,
  },
  mixins: [mixins],
  data() {
    return {
      item: {
        collectFlag: '0',
      },
      contextEntry: '',
    }
  },
  computed: {
    isStartO() {
      return this.item.collectFlag != '0' && this.item.collectFlag != null
    },
  },
  async beforeMount() {
    this.item = await speechCraftDetail({ id: this.$route.query.id })
  },
  created() {
    vendorServices.wxAgentConfig(async () => {
      this.contextEntry = await getWxEntry()
    })
  },
  mounted() {
    speechCount({ id: this.$route.query.id, countType: 2 })
    this.getTypeTree()
  },
  methods: {
    async sendMessage(content) {
      loading.showLoading()
      try {
        await wxSDK.sendChatMessage('text', {
          content,
        })
      } catch (error) {}
      loading.hideLoading()
    },
    async createById() {
      await this.$dialog.confirm({
        title: '提示',
        message: '确认复制当前话术至个人话术库?',
        confirmButtonColor: '#B9921A',
      })
      await copyToPersonal({ id: this.$route.query.id })
      this.$toast('已添加至个人话术')
    },
    async onStart() {
      await this.onChangeStart(this.item)
      this.item = await speechCraftDetail({ id: this.$route.query.id })
    },
    copy() {
      speechCount({ id: this.$route.query.id, countType: 1 })
      wx.setClipboardData({
        data: this.item.content, // 设置的
        success: (res) => {
          // this.$toast.success(this.$t('复制成功'))
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.send-action {
  text-align: right;
  margin-top: 8px;
  font-size: 12px;
  button {
    padding: 0 15px;
    /deep/ .van-button__text {
      font-size: 12px !important;
    }
  }
}
.item-wrap {
  margin: 16px 16px 66px;
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px 12px;
  .top {
    display: flex;
    justify-content: space-between;
    :last-child {
      align-self: flex-end;
      flex-shrink: 0;
      margin-left: 8px;
    }
  }
  .title {
    // color: #323233;
    font-size: 14px;
    // font-weight: bold;
  }
  .time {
    font-size: 12px;
    color: rgba(13, 23, 26, 0.45);
  }
  .desc {
    margin-top: 5px;
    font-size: 13px;
    line-height: 18px;
    color: rgba(13, 23, 26, 0.45);
  }
  .tag-wrap {
    margin-left: -5px;
  }
}
/deep/ .van-tabbar--fixed {
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
